function HomeGCard({ number, content }) {
  return (
    <div className="col-4 p-2 text-center">
      <h1 className="text-pjs">{number}<span className='text-first'>+</span></h1>
      <p>{content}</p>
    </div>
  );
}

export default HomeGCard;
