/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect, useContext } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { Toaster } from 'react-hot-toast';
import { library } from "@fortawesome/fontawesome-svg-core";
import { fab } from "@fortawesome/free-brands-svg-icons";
import {
  faUserSecret,
  faUser,
  faLocation,
  faTags,
  faPhone,
  faHome,
  faSearch,
  faPlus,
  faMinus,
  faTrashCan,
  faNairaSign,
  faCartShopping,
  faBars,
  faWarning,
  faStar,
  faMoneyBill,
  faLocationDot,
  faBasketShopping,
  faRightFromBracket,
  faReceipt,
  faCheckCircle,
  faTimesCircle,
  faCoins,
  faMoneyBills,
  faSackDollar,
  faCreditCard,
  faInfo,
  faMailReply,
  faEnvelope,
} from "@fortawesome/free-solid-svg-icons";

import { auth } from "../api";
import { AuthContext } from "../contexts/AuthContext";
import About from '../pages/About';
import Cart from "../pages/Cart";
import Contact from '../pages/Contact';
import Home from "../pages/Home";
import Layout from "../pages/Layout";
import Login from "../pages/Login";
import Menu from "../pages/Menu";
import Profile from "../pages/Profile";
import Register from "../pages/Register";


library.add(
  faStar,
  faLocationDot,
  faUserSecret,
  faUser,
  faHome,
  faSearch,
  faPlus,
  faMinus,
  faTrashCan,
  faNairaSign,
  faCartShopping,
  faBasketShopping,
  fab,
  faBars,
  faWarning,
  faPhone,
  faLocation,
  faTags,
  faMoneyBill,
  faRightFromBracket,
  faReceipt,
  faCheckCircle,
  faTimesCircle,
  faCoins,
  faMoneyBills,
  faSackDollar,
  faCreditCard,
  faInfo,
  faMailReply,
  faEnvelope,
);

const getCartFromStorage = () => {
  const cartString = localStorage.getItem("cart");
  return cartString ? JSON.parse(cartString) : [];
};

function App() {
  const [isLoading, setIsLoading] = useState(true);
  const { login } = useContext(AuthContext);
  const [cart, setCart] = useState(getCartFromStorage);
  const [currentOrder, setCurrentOrder] = useState([]);

  useEffect(() => {
    auth
      .get("/auth_status", { withCredentials: true })
      .then((res) => {
        setIsLoading(false);
        const data = res.data;
        if (data.authenticated) {
          // User is Authenticated, login user
          login(data.user);
        }
      })
      .catch((err) => {
        console.error("Error:", err);
      });
  }, []);

  useEffect(() => {
    localStorage.setItem("cart", JSON.stringify(cart));
  }, [cart]);

  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Layout cartItems={cart.length} />}>
          <Route index element={<Home setCurrentOrder={setCurrentOrder} />} />
          <Route
            path="menu"
            element={
              <Menu
                currentOrder={currentOrder}
                setCurrentOrder={setCurrentOrder}
                cart={cart}
                setCart={setCart}
              />
            }
          />
          <Route path="cart" element={<Cart cart={cart} setCart={setCart} />} />
          <Route path="login" element={<Login isLoading={isLoading} />} />
          <Route path="register" element={<Register isLoading={isLoading} />} />
          <Route path="profile" element={<Profile />} />
          <Route path="contact" element={<Contact />} />
          <Route path="about" element={<About />} />
        </Route>
      </Routes>
      <Toaster />
    </BrowserRouter>
  );
}

export default App;
