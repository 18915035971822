import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

function SearchBar({ setSearchText, placeholder }) {
  return (
    <div className="pb-4 row m-0 p-0 justify-content-center">
      <form className="col-11 col-md-6 col-lg-5 col-xl-4">
        <div className="input-group">
          <input
            type="search"
            className="form-control"
            placeholder={placeholder ? placeholder : "Search..."}
            aria-label="Search..."
            aria-describedby="button-search"
            onChange={(e) => setSearchText(e.target.value)}
          />
          <button
            className="btn border-0 btn-warning bg-first text-white"
            type="button"
            id="button-search"
          >
            <FontAwesomeIcon className="m-0 " icon="search" />
          </button>
        </div>
      </form>
    </div>
  );
}

export default SearchBar;
