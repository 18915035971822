import { useState, useContext } from "react";
import { Navigate } from "react-router-dom";
import validator from "validator";
import { RotatingLines } from "react-loader-spinner";
import { AuthContext } from "../contexts/AuthContext";
import { api, auth } from "../api";
import Input from "../components/Input";

import "../styles/index.css";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import google from "../images/google.png";
import facebook from "../images/facebook.png";
import apple from "../images/apple.png";

function Register() {
  const { login, user } = useContext(AuthContext);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [registering, setRegistering] = useState(false);

  // states for error handling
  const [error, setError] = useState(false);
  const [firstNameError, setFirstNameError] = useState({ active: false });
  const [lastNameError, setLastNameError] = useState({ active: false });
  const [emailError, setEmailError] = useState({ active: false });
  const [phoneError, setPhoneError] = useState({ active: false });
  const [passwordError, setPasswordError] = useState({ active: false });
  const [confirmPasswordError, setConfirmPasswordError] = useState({
    active: false,
  });
  const [registerError, setRegisterError] = useState({ active: false });

  const handleSubmit = (e) => {
    e.preventDefault();

    setRegisterError({ active: false });

    if (firstName.length === 0) {
      setFirstNameError({
        active: true,
        message: "First Name cannot be blank",
      });
      return;
    } else {
      setFirstNameError({ active: false });
    }

    if (lastName.length === 0) {
      setLastNameError({ active: true, message: "Last Name cannot be blank" });
      return;
    } else {
      setLastNameError({ active: false });
    }

    if (email.length === 0) {
      setEmailError({ active: true, message: "Email cannot be blank" });
      return;
    } else {
      setEmailError({ active: false });
    }

    if (phone.length === 0) {
      setPhoneError({ active: true, message: "Phone Number cannot be blank" });
      return;
    } else {
      setPhoneError({ active: false });
    }

    if (password.length === 0) {
      setPasswordError({ active: true, message: "Password cannot be blank" });
      return;
    } else {
      setPasswordError({ active: false });
    }

    if (confirmPassword.length === 0) {
      setConfirmPasswordError({ active: true, message: "Confirm Password" });
      return;
    } else {
      setConfirmPasswordError({ active: false });
    }

    setRegistering(true);

    const userData = {
      first_name: firstName,
      last_name: lastName,
      email: email,
      phone_number: phone,
      password: password,
    };

    api
      .post("/users", userData, { withCredentials: true })
      .then((res) => {
        auth
          .post("/login", { email, password }, { withCredentials: true })
          .then((res) => {
            const data = res.data;
            login(data.user);
          })
          .catch((err) => {
            setRegisterError({
              active: true,
              message: "An error occurred. Please try again later.",
            });
          });
        setRegistering(false);
      })
      .catch((err) => {
        setRegistering(false);
        if (err.response) {
          setRegisterError({
            active: true,
            message: err.response.data.message,
          });
        } else {
          setRegisterError({
            active: true,
            message: "An error occurred. Please try again later.",
          });
        }
      });
  };

  return user ? (
    <Navigate replace to="/" />
  ) : (
    <div className="container-fluid m-0 p-0 buffet max-height">
      <div className="row m-0 p-0 justify-content-end">
        <div className="col-12 col-lg-6 p-4 px-1 text-center bg-light max-height">
          <div className="row m-0 p-0 text-dark justify-content-center">
            <h2 className="col-12 col-sm-11 col-md-9 col-lg-8 my-2 text-pop">
              Create Food Spice Account
            </h2>
            <p className="col-12 col-sm-11 col-md-9 col-lg-8 my-2 text-pop">
              Create an account to acces all features, special offers and order
              histories
            </p>
          </div>
          <form
            className="row m-0 my-3 py-2 justify-content-center text-dark"
            onSubmit={handleSubmit}
          >
            {registerError.active && (
              <div className="col-10 col-lg-9 col-xl-8 col-xxl-7" role="alert">
                <div className="p-2  alert alert-danger mt-2 mb-3 w-100">
                  {registerError.message}
                </div>
              </div>
            )}
            <div className="col-10 col-lg-9 col-xl-8 col-xxl-7">
              <Input
                type="text"
                placeholder="First Name"
                onChange={(e) => {
                  setFirstName(e.target.value);
                  if (!validator.isAlpha(e.target.value)) {
                    setFirstNameError({
                      active: true,
                      message: "First Name must contain only letters",
                    });
                    setError(true);
                  } else {
                    setFirstNameError({ active: false });
                    setError(false);
                  }
                }}
                className="p-2 form-control-lg bg-accent mt-2 mb-3 w-100"
                value={firstName}
                error={firstNameError}
              />
            </div>
            <div className="col-10 col-lg-9 col-xl-8 col-xxl-7">
              <Input
                type="text"
                placeholder="Last Name"
                onChange={(e) => {
                  setLastName(e.target.value);
                  if (!validator.isAlpha(e.target.value)) {
                    setLastNameError({
                      active: true,
                      message: "Last Name must contain only letters",
                    });
                    setError(true);
                  } else {
                    setLastNameError({ active: false });
                    setError(false);
                  }
                }}
                className="p-2 form-control-lg bg-accent mt-2 mb-3 w-100"
                value={lastName}
                error={lastNameError}
              />
            </div>
            <div className="col-10 col-lg-9 col-xl-8 col-xxl-7">
              <Input
                type="email"
                placeholder="Email"
                onChange={(e) => {
                  setEmail(e.target.value);
                  if (!validator.isEmail(e.target.value)) {
                    setEmailError({
                      active: true,
                      message: "Invalid Email",
                    });
                    setError(true);
                  } else {
                    setEmailError({ active: false });
                    setError(false);
                  }
                }}
                className="p-2 form-control-lg bg-accent mt-2 mb-3 w-100"
                value={email}
                error={emailError}
              />
            </div>
            <div className="col-10 col-lg-9 col-xl-8 col-xxl-7">
              <Input
                type="text"
                placeholder="Phone Number"
                onChange={(e) => {
                  setPhone(e.target.value);
                  if (!validator.isMobilePhone(e.target.value)) {
                    setPhoneError({
                      active: true,
                      message: "Invalid Phone Number",
                    });
                    setError(true);
                  } else {
                    setPhoneError({ active: false });
                    setError(false);
                  }
                }}
                className="p-2 form-control-lg bg-accent mt-2 mb-3 w-100"
                value={phone}
                error={phoneError}
              />
            </div>
            <div className="col-10 col-lg-9 col-xl-8 col-xxl-7">
              <Input
                type="password"
                placeholder="Password"
                onChange={(e) => {
                  setPassword(e.target.value);
                  if (e.target.value.length < 6) {
                    setPasswordError({
                      active: true,
                      message: "Password must be at least 6 characters",
                    });
                    setError(true);
                  } else {
                    setPasswordError({ active: false });
                    setError(false);
                  }
                }}
                className="p-2 form-control-lg bg-accent mt-2 mb-3 w-100"
                value={password}
                error={passwordError}
              />
            </div>
            <div className="col-10 col-lg-9 col-xl-8 col-xxl-7">
              <Input
                type="password"
                placeholder="Confirm Password"
                onChange={(e) => {
                  setConfirmPassword(e.target.value);
                  if (e.target.value !== password) {
                    setConfirmPasswordError({
                      active: true,
                      message: "Passwords do not match",
                    });
                    setError(true);
                  } else {
                    setConfirmPasswordError({ active: false });
                    setError(false);
                  }
                }}
                className="p-2 form-control-lg bg-accent mt-2 mb-3 w-100"
                value={confirmPassword}
                error={confirmPasswordError}
              />
            </div>
            <div className="col-10 col-lg-9 col-xl-8 col-xxl-7 mt-3">
              <div className="d-grid">
                {registering ? (
                  <button
                    type="submit"
                    className="btn btn-lg btn-warning bg-first text-light"
                  >
                    Creating account
                    <RotatingLines
                      strokeColor="white"
                      strokeWidth="5"
                      animationDuration="0.75"
                      width="20"
                      visible={true}
                    />
                  </button>
                ) : (
                  <button
                    type="submit"
                    className="btn btn-lg btn-warning bg-first text-light"
                    disabled={error}
                  >
                    Register
                  </button>
                )}
              </div>
            </div>
          </form>
          <div className="forget">
            <p className="text-pjs">
              Already have an account?{" "}
              <a
                className=" link-underline link-underline-opacity-0 text-first"
                href="/login"
              >
                Login
              </a>
            </p>
            <img className="mx-3" width="40" src={google} alt="google" />
            <img className="mx-3" width="40" src={facebook} alt="facebook" />
            <img className="mx-3" width="40" src={apple} alt="apple" />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Register;
