import { useState, useEffect, useContext } from "react";
import { AuthContext } from "../contexts/AuthContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import toast from 'react-hot-toast';


function Order({ currentOrder, setCurrentOrder, cart, setCart }) {
  const [quantities, setQuantities] = useState({});
  const { user } = useContext(AuthContext);

  useEffect(() => {
    const initialQuantities = currentOrder.reduce((acc, order) => {
      if (quantities[order.id]) acc[order.id] = quantities[order.id];
      else if (!acc[order.id]) acc[order.id] = 1;
      return acc;
    }, {});
    setQuantities(initialQuantities);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentOrder]);

  const handleIncrement = (id) => {
    setQuantities((prevQuantities) => ({
      ...prevQuantities,
      [id]: Math.min(prevQuantities[id] + 1, 10),
    }));
  };

  const handleDecrement = (id) => {
    setQuantities((prevQuantities) => ({
      ...prevQuantities,
      [id]: Math.max(prevQuantities[id] - 1, 1),
    }));
  };

  const calculateItemPrice = (id, price) => {
    return price * quantities[id];
  };

  const calculateTotal = () => {
    return currentOrder.reduce(
      (total, order) => total + calculateItemPrice(order.id, order.price),
      0
    );
  };

  const removeItem = (id) => {
    setCurrentOrder(currentOrder.filter((order) => order.id !== id));
  };

  const generateOrderItem = () => {
    const orderItem = {
      id: `Order #${cart.length + 1}`,
      name: `Order #${cart.length + 1}`,
      price: calculateTotal().toFixed(2),
      items: currentOrder.map((order) => ({
        name: order.name,
        quantity: quantities[order.id],
        price: calculateItemPrice(order.id, order.price).toFixed(2),
      })),
    };
    setCart([...cart, orderItem]);
    toast(
      <div>Order added to cart</div>,
      {
        icon: <FontAwesomeIcon icon="cart-shopping" />,
      }
    );
    setCurrentOrder([]);
  };

  return (
    <div className="p-2">
      <div className="d-flex justify-content-between">
        <button
          type="button"
          className="btn-close"
          data-bs-dismiss="offcanvas"
          aria-label="Close"
        ></button>
        <button
          type="button"
          className="btn btn-danger p-1 p-lg-2 text-small"
          data-bs-dismiss="offcanvas"
          onClick={() => setCurrentOrder([])}
        >
          Reset order
        </button>
      </div>
      <div className="pt-2 pt-md-3 pt-lg-4 text-center">
        <FontAwesomeIcon className="p-3 bg-first rounded-circle" icon="user" />
        <h4>Welcome, {user ? user.last_name : "Anon"}</h4>
      </div>
      <div className="pt-2 pt-lg-4">
        <h5>Order # {cart.length + 1}</h5>
      </div>
      <hr />
      <div className="col-12 py-2">
        {currentOrder.map((order) => (
          <div key={order.id} className="d-flex align-items-center pb-2">
            <div className="col-4 pt-3">
              <p className="text-dark">{order.name}</p>
            </div>
            <div className="input-group col justify-content-center align-items-center">
              <button
                type="button"
                className="btn btn-danger"
                onClick={() => handleDecrement(order.id)}
              >
                -
              </button>
              <input
                className="bg-accent col-3 m-0 p-0 ps-1"
                type="text"
                value={quantities[order.id] || 0}
                disabled
                readOnly
              />
              <button
                type="button"
                className="btn btn-success"
                onClick={() => handleIncrement(order.id)}
              >
                +
              </button>
            </div>
            <div className="col-3 pt-3">
              <p className="text-dark text-end">
                <FontAwesomeIcon icon="naira-sign" />
                {calculateItemPrice(order.id, order.price).toFixed(2)}
              </p>
            </div>
            <div className="col-1 py-2">
              <button
                type="button"
                className="btn"
                onClick={() => {
                  removeItem(order.id);
                  toast.success(`Removed ${order.name} from order`);
                }}
              >
                <FontAwesomeIcon className="text-danger" icon="trash-can" />
              </button>
            </div>
          </div>
        ))}
      </div>
      <hr />
      <div className="col-12 py-2">
        <h4>
          Total: <FontAwesomeIcon icon="naira-sign" />
          {calculateTotal().toFixed(2)}
        </h4>
      </div>
      <hr />
      <div className="d-flex justify-content-center py-4">
        <button
          type="button"
          className="btn btn-dark bg-first border border-0"
          onClick={generateOrderItem}
          data-bs-dismiss="offcanvas"
        >
          Add to cart <FontAwesomeIcon icon="cart-shopping" />{" "}
        </button>
      </div>
    </div>
  );
}

export default Order;
