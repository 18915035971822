import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

function Category({ image, category, number }) {
  return (
    // Use javascript to toggle text-white and bg-first on thd div below for selected filter
    <div className="card col-4 col-sm-3 col-lg-2 col-xl-1 col-xxl-1 p-1 p-xxl-2 mx-1 mx-xl-2border-1 rounded-0 bg-light">
      <div className="text-center pt-1">
        <FontAwesomeIcon
          className="border border-warning rounded-circle p-1"
          icon="cart-shopping"
        />
      </div>
      <div className="card-body text-center p-0">
        <p className="card-title text-pop text-small">{category}</p>
        <p className="card-text text-pop text-small">{number} Items</p>
      </div>
    </div>
  );
}

export default Category;
