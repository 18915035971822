import React from 'react'

function About() {
  return (
    <div className="container-fluid m-0 p-0">
      <h1 className="text-pop text-center mb-2">About</h1>
      <div className="row m-0 text-center justify-content-center my-4">
        <div className="col-12">
          <h2 className="mb-4 text-inter">Our Menu</h2>
        </div>
        <div className="col-8 col-lg-6 col-xl-4">
          <p>
            We have a wide range of menu to choose from. Click the button below
            to view our menu
          </p>
        </div>
        <div className="col-12">
          <a href="/menu" className="btn btn-dark border-0 bg-first text-light">
            View Menu
          </a>
        </div>
      </div>
    </div>
  );
};

export default About;
