import { useState, useContext } from "react";
import validator from "validator";
import { RotatingLines } from "react-loader-spinner";
import { api } from "../api";
import { AuthContext } from "../contexts/AuthContext";
import Input from "./Input";
import toast from 'react-hot-toast';

function ProfileEditForm({ setEditingProfile }) {
  const { user, login } = useContext(AuthContext);

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [updating, setUpdating] = useState(false);

  // Error states
  const [error, setError] = useState(false);
  const [firstNameError, setFirstNameError] = useState({ active: false });
  const [lastNameError, setLastNameError] = useState({ active: false });
  const [emailError, setEmailError] = useState({ active: false });
  const [phoneNumberError, setPhoneNumberError] = useState({ active: false });
  const [updateError, setUpdateError] = useState({ active: false });

  const handleUpdate = async () => {
    if (
      firstName.length === 0 &&
      lastName.length === 0 &&
      email.length === 0 &&
      phoneNumber.length === 0
    ) {
      setUpdateError({ active: true, message: "All fields cannot be blank" });
      return;
    } else {
      setUpdateError({ active: false });
    }

    setUpdating(true);
    const data = {};
    if (firstName.length > 0) data.first_name = firstName;
    if (lastName.length > 0) data.last_name = lastName;
    if (email.length > 0) data.email = email;
    if (phoneNumber.length > 0) data.phone_number = phoneNumber;
    const toastId = toast.loading('Updating...');

    try {
      const response = await api.put(`/users/${user.id}/`, data);
      login(response.data);
      setEditingProfile(false);
    } catch (error) {
      if (error.response) {
        setUpdateError({ active: true, message: error.response.data.message });
      } else {
        setUpdateError({
          active: true,
          message: "An error occurred, please try again",
        });
      }
      toast.error('An error occurred, please try again');
    } finally {
      toast.dismiss(toastId);
      setUpdating(false);
      toast.success('Successfully Updated');
    }
  };


  return (
    <div className="row col-md-9 col-lg-6 text-center justify-content-center">
      {updateError.active && (
        <div className="col-10 col-lg-9 col-xl-8 col-xxl-7" role="alert">
          <div className="p-2  alert alert-danger mt-2 mb-3 w-100">
            {updateError.message}
          </div>
        </div>
      )}
      <p>Only fill the details you want to edit</p>
      <form
        className="row m-0 p-0 my-4 py-4 justify-content-center text-dark"
      >
        <div className="col-12 m-0 p-0">
          <div className='input-group row m-0 p-0 mb-3'>
            <span className="input-group-text col-4 col-md-3 col-xxl-2">First name</span>
            <Input
              type="text"
              className="form-control col-8 col-md-9 col-xxl-10"
              onChange={(e) => {
                setFirstName(e.target.value);
                if (
                  e.target.value.length > 0 &&
                  !validator.isAlpha(e.target.value)
                ) {
                  setFirstNameError({
                    active: true,
                    message: "First name must contain only letters",
                  });
                  setError(true);
                } else {
                  setFirstNameError({ active: false });
                  setError(false);
                }
              }}
              aria-label="First Name"
              value={firstName}
              error={firstNameError}
              placeholder={user.first_name}
            />
          </div>
        </div>
        <div className="col-12 m-0 p-0">
          <div className='input-group row m-0 p-0 mb-3'>
            <span className="input-group-text col-4 col-md-3 col-xxl-2">Last name</span>
            <Input
              type="text"
              className="form-control col-8 col-md-9 col-xxl-10"
              onChange={(e) => {
                setLastName(e.target.value);
                if (
                  e.target.value.length > 0 &&
                  !validator.isAlpha(e.target.value)
                ) {
                  setLastNameError({
                    active: true,
                    message: "Last name must contain only letters",
                  });
                  setError(true);
                } else {
                  setLastNameError({ active: false });
                  setError(false);
                }
              }}
              aria-label="Last Name"
              value={lastName}
              error={lastNameError}
              placeholder={user.last_name}
            />
          </div>
        </div>
        <div className="col-12 m-0 p-0">
          <div className='input-group row m-0 p-0 mb-3'>
            <span className="input-group-text col-4 col-md-3 col-xxl-2">Email</span>
            <Input
              type="email"
              onChange={(e) => {
                setEmail(e.target.value);
                if (
                  e.target.value.length > 0 &&
                  !validator.isEmail(e.target.value)
                ) {
                  setEmailError({ active: true, message: "Invalid Email" });
                  setError(true);
                } else {
                  setEmailError({ active: false });
                  setError(false);
                }
              }}
              className="form-control"
              aria-label="Email"
              value={email}
              error={emailError}
              placeholder={user.email}
            />
          </div>
        </div>
        <div className="col-12 m-0 p-0">
          <div className='input-group row m-0 p-0 mb-3'>
            <span className="input-group-text col-4 col-md-3 col-xxl-2">Phone</span>
            <Input
              type="text"
              onChange={(e) => {
                setPhoneNumber(e.target.value);
                if (
                  e.target.value.length > 0 &&
                  !validator.isMobilePhone(e.target.value)
                ) {
                  setPhoneNumberError({
                    active: true,
                    message: "Last name must contain only letters",
                  });
                  setError(true);
                } else {
                  setPhoneNumberError({ active: false });
                  setError(false);
                }
              }}
              className="form-control"
              aria-label="Last Name"
              value={phoneNumber}
              error={phoneNumberError}
              placeholder={user.phone_number}
            />
          </div>
        </div>
        {updating ? (
          <button className="btn btn-outline-success" type="button">
            updating
            <RotatingLines
              strokeColor="white"
              strokeWidth="5"
              animationDuration="0.75"
              width="20"
              visible={true}
            />
          </button>
        ) : (
          <div className='row justify-content-center'>
            <div className='m-0 p-0 col pe-4 text-end'>
              <button
                className="btn btn-outline-success"
                type="button"
                disabled={error}
                onClick={handleUpdate}
              >
                Save
              </button>
            </div>
            <div className='m-0 p-0 col ps-4 text-start'>
              <button
                className="btn btn-outline-danger"
                type="button"
                onClick={() => setEditingProfile(false)}
              >Cancel</button>
            </div>
          </div>
        )}
      </form>
    </div>
  );
}

export default ProfileEditForm;
