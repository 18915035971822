import { useContext } from "react";
import { Link, useLocation } from "react-router-dom";
import { AuthContext } from "../contexts/AuthContext";
import { auth } from "../api";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import "../styles/index.css";

function Header({ cartItems }) {
  const { user, logout } = useContext(AuthContext);
  const location = useLocation();

  const handleLogout = () => {
    auth
      .delete("/logout", { withCredentials: true })
      .then(() => {
        logout();
      })
      .catch((err) => {
        console.log("Error:", err);
      });
  };

  return (
    <header className="container-fluid">
      <nav className="navbar navbar-expand-lg p-4">
        <div className="container-fluid justify-content-between">
          <div className="col-3 col-lg-4">
            <Link to="/" className="navbar-brand text-dark">
              IWanBuy
            </Link>
          </div>
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarContent"
            aria-controls="navbarContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse" id="navbarContent">
            <ul className="navbar-nav justify-content-end">
              <li className="nav-item">
                <Link to="/" className="nav-link text-dark">
                  Home
                </Link>
              </li>
              <li className="nav-item">
                <Link to="/menu" className="nav-link text-dark">
                  Menu
                </Link>
              </li>
              <li className="nav-item">
                <Link to="/contact" className="nav-link text-dark">
                  Contact us
                </Link>
              </li>
              <li className="nav-item">
                <Link to="/cart" className="nav-link text-dark">
                  <FontAwesomeIcon icon="cart-shopping" className='text-first' />{" "}
                  <sup className='text-first'>{cartItems}</sup>
                </Link>
              </li>
              <li className="nav-item">
                {user ? (
                  location.pathname !== "/profile" ? (
                    <Link
                      to="/profile"
                      type="button"
                      className="btn rounded-circle border border-1 border-success"
                    >
                      <FontAwesomeIcon icon="user" />
                    </Link>
                  ) : (
                    <button
                      onClick={handleLogout}
                      type="button"
                      className="btn rounded-circle border border-1 border-danger"
                    >
                      <FontAwesomeIcon icon="right-from-bracket" />
                    </button>
                  )
                ) : (
                  <Link
                    to="/login"
                    type="button"
                    className="btn rounded-circle border border-1 border-danger"
                  >
                    <FontAwesomeIcon icon="user-secret" />
                  </Link>
                )}
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </header>
  );
}

export default Header;
