import { useContext, useRef, useState } from "react";
import validator from "validator";
import { Link, useNavigate } from "react-router-dom";
import { useReactToPrint } from "react-to-print";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { PaystackButton } from "react-paystack";
import { AuthContext } from "../contexts/AuthContext";
import Receipt from "../components/Receipt";
import Input from "../components/Input";
import { api } from "../api";
import toast from "react-hot-toast";

function Cart({ cart, setCart }) {
  const { user } = useContext(AuthContext);
  const [receiverName, setReceiverName] = useState(user ? user.first_name : "");
  const [receiverPhone, setReceiverPhone] = useState(
    user ? user.phone_number : ""
  );
  const [delivery, setDelivery] = useState(false);
  const [deliveryAddress, setDeliveryAddress] = useState("");

  const receiptRef = useRef();

  const [error, setError] = useState(false);
  const [receiverNameError, setReceiverNameError] = useState({
    active: false,
    message: "",
  });
  const [receiverPhoneError, setReceiverPhoneError] = useState({
    active: false,
    message: "",
  });
  const [deliveryAddressError, setDeliveryAddressError] = useState({
    active: false,
    message: "",
  });
  const [otherError, setOtherError] = useState({
    active: false,
    message: "",
  });

  const [isLoading, setIsLoading] = useState(false);
  const [successText, setSuccessText] = useState("");

  const [confirmed, setConfirmed] = useState(false);
  const [orders, setOrders] = useState([]);

  const navigate = useNavigate();

  const handleNavigate = () => {
    navigate("/login", {
      state: { from: "/cart", reason: "You must login to place an order" },
    });
  };

  const removeItem = (id) => {
    const newCart = cart.filter((order) => order.id !== id);
    setCart(
      newCart.map((order, index) => ({
        ...order,
        id: `Order #${index + 1}`,
        name: `Order #${index + 1}`,
      }))
    );
    toast.success(`${id} removed from cart`);
  };

  const handlePrint = useReactToPrint({
    content: () => receiptRef.current,
  });

  const checkForErrors = () => {
    if (receiverName.length === 0) {
      setReceiverNameError({
        active: true,
        message: "Name cannot be blank",
      });
      return true;
    } else {
      setReceiverNameError({ active: false });
    }

    if (receiverPhone.length === 0) {
      setReceiverPhoneError({
        active: true,
        message: "Phone number cannot be blank",
      });
      return true;
    } else {
      setReceiverPhoneError({ active: false });
    }

    if (delivery && deliveryAddress.length === 0) {
      setDeliveryAddressError({
        active: true,
        message: "Address cannot be blank",
      });
      return true;
    } else {
      setDeliveryAddressError({ active: false });
    }

    return false;
  };

  const handleConfirmOrders = async () => {
    if (checkForErrors()) return false;
    const refr = "C-" + new Date().getTime().toString();

    setIsLoading(true);

    const promises = cart.map(async (order) => {
      const data = {
        receiver_name: receiverName,
        receiver_phone: receiverPhone,
        delivery: delivery,
        paid: false,
        cart_items: order.items.map((item) => ({
          name: item.name,
          quantity: item.quantity,
        })),
        reference: refr,
      };

      if (delivery) {
        data.delivery_address = deliveryAddress;
        data.delivery_fee = 2000;
      }

      try {
        const response = await api.post(`/users/${user.id}/orders`, data);
        return response.data;
      } catch (e) {
        throw e;
      }
    });

    try {
      const results = await Promise.all(promises);
      setOrders(results);
      setConfirmed(true);
      setOtherError({ active: false });
    } catch (e) {
      console.error(e);
      if (
        (e.response && e.response.status === 401) ||
        e.response.status === 400
      ) {
        setOtherError({
          active: true,
          message: e.response.data.message,
        });
      } else {
        setOtherError({
          active: true,
          message: "An error occured. Please try again.",
        });
      }
    } finally {
      setIsLoading(false);
    }
  };

  const handleSuccess = (successText) => {
    setIsLoading(false);
    setSuccessText(successText);
    setCart([]);
  };

  const handlePayStackSuccess = async (reference) => {
    const promises = orders.map(async (order) => {
      const data = {
        paid: true,
        reference: reference.reference,
      };
      try {
        const response = await api.put(
          `/users/${user.id}/orders/${order.id}`,
          data
        );
        return response.data;
      } catch (e) {
        throw e;
      }
    });

    try {
      const results = await Promise.all(promises);
      setOrders(results);
      handleSuccess(
        "Order placed successfully. Save receipt and take to restaurant to collect your order"
      );
    } catch (e) {
      console.error(e);
    }
  };

  const handlePaystackClose = () => {
    // WTF ARE WE DOING HERE
  };

  const config = {
    reference: "O-" + new Date().getTime().toString(),
    email: user ? user.email : "",
    publicKey: "pk_test_24c007f054ecaebe24148866c7bee1d2886bdfff",
    amount:
      (cart.reduce((total, order) => total + parseFloat(order.price), 0) +
        (delivery ? 2000 : 0)) *
      100,
    currency: "NGN",
    metadata: {
      custom_fields: [
        orders.map((order) => ({
          display_name: "Order ID",
          variable_name: "order_id",
          value: order.id,
        })),
      ],
    },
  };

  const componentProps = {
    ...config,
    text: "Pay Online",
    onSuccess: (reference) => handlePayStackSuccess(reference),
    onClose: handlePaystackClose,
  };

  return cart.length > 0 ? (
    <div className="p-4">
      {otherError.active && (
        <div className="col-10 col-lg-8 alert alert-danger" role="alert">
          {otherError.message}
        </div>
      )}
      <p className="h2 text-center">Cart Summary</p>
      <div className="d-flex row">
        <div className="col-12 col-lg-6">
          <hr />
          <div className="p-1 px-2">
            {cart.map((order, index) => (
              <div className="py-2 rounded-5 mb-4 bg-second" key={index}>
                <div className="d-flex m-0 p-0 px-3 justify-content-between align-items-center">
                  <div className="m-0 p-0">
                    <h3 className="m-0 p-0">{order.name}</h3>
                  </div>
                  {!confirmed && (
                    <div className="m-0 p-0">
                      <button
                        type="button"
                        className="btn"
                        onClick={() => removeItem(order.id)}
                      >
                        <FontAwesomeIcon
                          className="text-danger"
                          icon="trash-can"
                        />
                      </button>
                    </div>
                  )}
                </div>
                <div className="m-0 p-2">
                  <ul className="list-group m-0 p-0 bg-second">
                    {order.items.map((item, index) => (
                      <li
                        className="d-flex row justify-content-between list-group-item bg-second border border-0 m-0 p-2"
                        key={index}
                      >
                        <p className="m-0 p-0 col-6">{item.name}</p>
                        <p className="m-0 p-0 col-2 text-end">
                          x {item.quantity}
                        </p>
                        <p className="m-0 p-0 col-4 text-end">
                          <FontAwesomeIcon icon="naira-sign" />
                          {item.price}
                        </p>
                      </li>
                    ))}
                    <li className="d-flex bg-second justify-content-between list-group-item text-first border border-0 m-0 p-2">
                      <p className="m-0">Sub-total</p>
                      <p className="m-0 text-end">
                        <FontAwesomeIcon icon="naira-sign" />
                        {order.price}
                      </p>
                    </li>
                  </ul>
                </div>
              </div>
            ))}
            {delivery && (
              <div className="d-flex justify-content-between text-first p-2 mb-3">
                <p className="m-0">Delivery Fee</p>
                <p className="m-0 text-end">
                  <FontAwesomeIcon icon="naira-sign" />
                  2000
                </p>
              </div>
            )}
          </div>
          <div className="row text-first justify-content-between ps-2">
            <p className="col display-6 m-0">Total:</p>
            <p className="col text-end display-6 m-0">
              <FontAwesomeIcon icon="naira-sign" />
              {cart.reduce(
                (total, order) => total + parseFloat(order.price),
                0
              ) + (delivery ? 2000 : 0)}
            </p>
          </div>
        </div>
        <div className="col-12 col-lg-6 m-1 p-0">
          <hr />
          <div className="m-0 p-0">
            <h3 className="text-center">Receiver Information</h3>
            <div className="p-2 px-4">
              <Input
                type="text"
                placeholder="Enter receiver's name"
                onChange={(e) => {
                  setReceiverName(e.target.value);
                  if (!validator.isAlpha(e.target.value)) {
                    setReceiverNameError({
                      active: true,
                      message: "Name must contain only letters",
                    });
                    setError(true);
                  } else {
                    setReceiverNameError({ active: false });
                    setError(false);
                  }
                }}
                className="p-2 form-control-lg bg-accent my-2 w-100"
                value={receiverName}
                error={receiverNameError}
                readOnly={confirmed}
              />
              <Input
                type="text"
                placeholder="Enter receiver's phone number"
                onChange={(e) => {
                  setReceiverPhone(e.target.value);
                  if (!validator.isMobilePhone(e.target.value)) {
                    setReceiverPhoneError({
                      active: true,
                      message: "Phone number must be valid",
                    });
                    setError(true);
                  } else {
                    setReceiverPhoneError({ active: false });
                    setError(false);
                  }
                }}
                className="p-2 form-control-lg bg-accent mt-2 w-100"
                value={receiverPhone}
                error={receiverPhoneError}
                readOnly={confirmed}
              />
              <br />
              <label htmlFor="delivery" className="form-label mt-2">
                Delivery
              </label>
              <input
                type="checkbox"
                id="delivery"
                className="form-check-input mt-3 ms-2"
                onChange={(e) => {
                  setDelivery(e.target.checked);
                  if (!e.target.checked) {
                    setDeliveryAddressError({
                      active: false,
                      message: "",
                    });
                    if (
                      !receiverNameError.active &&
                      !receiverPhoneError.active
                    ) {
                      setError(false);
                    }
                  }
                }}
                checked={delivery}
                disabled={confirmed}
              />
              {delivery && (
                <>
                  <p style={{ fontSize: "0.8rem" }}>
                    Delivery attracts a delivery fee of N2000. Please check new
                    order price before you confirm
                  </p>
                  <Input
                    type="text"
                    placeholder="Enter delivery address"
                    onChange={(e) => {
                      setDeliveryAddress(e.target.value);
                      if (e.target.value.length < 10) {
                        setDeliveryAddressError({
                          active: true,
                          message: "Address must be more than 10 characters",
                        });
                        setError(true);
                      } else {
                        setDeliveryAddressError({ active: false });
                        setError(false);
                      }
                    }}
                    className="p-2 form-control-lg bg-accent w-100"
                    value={deliveryAddress}
                    error={deliveryAddressError}
                    readOnly={confirmed}
                  />
                </>
              )}
            </div>
          </div>
          <br />
        </div>
      </div>
      <div className="d-flex col-12 col-lg-6 p-2 text-center justify-content-center">
        {user ? (
          <>
            {confirmed ? (
              <>
                <div className="px-2 mx-0 mx-lg-3 mx-xl-5">
                  <PaystackButton
                    className="btn btn-success"
                    {...componentProps}
                  />
                </div>
                <div className="px-2 mx-0 mx-lg-3 mx-xl-5">
                  <button
                    type="button"
                    className="btn btn-success"
                    onClick={() =>
                      handleSuccess(
                        "Order placed successfully. Save receipt and take to restaurant to collect your order"
                      )
                    }
                    disabled={error || isLoading}
                  >
                    Pay cash
                  </button>
                </div>
              </>
            ) : (
              <div className="px-2">
                <button
                  type="button"
                  className="btn btn-success"
                  onClick={() => handleConfirmOrders()}
                  disabled={error || isLoading}
                >
                  Confirm order
                </button>
              </div>
            )}
          </>
        ) : (
          <div className="px-2">
            <button
              type="button"
              className="btn btn-success"
              onClick={handleNavigate}
              disabled={error}
            >
              Place Order
            </button>
          </div>
        )}
      </div>
      {orders.length > 0 && (
        <div style={{ display: "none" }}>
          <Receipt ref={receiptRef} orders={orders} />
        </div>
      )}
    </div>
  ) : (
    <div className="p-4" style={{ height: "70vh" }}>
      {successText ? (
        <div className="m-0 p-0 text-center row justify-content-center">
          <h2 className="col-10 col-lg-7 text-success">{successText}</h2>
          <div className="col-10 col-lg-7">
            <button
              type="button"
              className="btn btn-success"
              onClick={() => handlePrint()}
              disabled={error || isLoading}
            >
              Print Receipt
            </button>
          </div>
          {orders.length > 0 && (
            <div style={{ display: "none" }}>
              <Receipt ref={receiptRef} orders={orders} />
            </div>
          )}
        </div>
      ) : (
        <>
          <h1>Cart Summary</h1>
          <p>
            Your cart is empty, click the{" "}
            <FontAwesomeIcon icon="basket-shopping" className="text-first" />{" "}
            button on the{" "}
            <Link to={"/menu"} className="text-first">
              Menu
            </Link>{" "}
            to add orders to your cart.
          </p>
        </>
      )}
    </div>
  );
}

export default Cart;
