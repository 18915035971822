import React from "react";
import QRCode from "qrcode.react";
import OrderDisplay from "./OrderDisplay";

const Receipt = React.forwardRef(({ orders }, ref) => {
  const receiptData = JSON.stringify(orders.map((order) => order.id));

  return (
    <div
      ref={ref}
      className="border p-3 text-dark"
      style={{ width: "300px", margin: "0 auto" }}
    >
      <div className="m-0 p-0">
        <h2 className="text-center">Food Spice Receipt</h2>
        <br />
        <h5 className="text-dark m-0 p-0 pb-2">Order Details</h5>
        <hr className="my-2" />
      </div>
      <OrderDisplay orders={orders} />
      <div className="text-center mt-4">
        <QRCode value={receiptData} />
      </div>
    </div>
  );
});

export default Receipt;
