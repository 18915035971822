import { Outlet } from "react-router-dom";
import Header from "../components/Header";
import Footer from "../components/Footer";

function Layout({ cartItems }) {
  return (
    <div className="container-fluid m-0 p-0 text-pop text-dark">
      <Header cartItems={cartItems} />
      <Outlet />
      <Footer />
    </div>
  );
}

export default Layout;
