import { useContext } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { AuthContext } from "../contexts/AuthContext";

function OrderDisplay({ orders }) {
  const { user } = useContext(AuthContext);

  return (
    <>
      <p className="m-0 p-0">Name: {user.first_name + " " + user.last_name}</p>
      <p className="m-0 p-0">
        Date: {new Date(orders[0].created_at).toLocaleDateString()}
      </p>
      <p className="m-0 p-0">Receiver name: {orders[0].receiver_name}</p>
      <p className="m-0 p-0">Receiver phone: {orders[0].receiver_phone}</p>
      {orders[0].delivery && (
        <p className="m-0 p-0">
          Delivery address: {orders[0].delivery_address}
        </p>
      )}
      {orders[0].reference && orders[0].reference.startsWith("O") ? (
        <div className="m-0 p-0 row justify-content-between">
          <div className="m-0 p-0 row">
            <p className="col m-0 p-0 text-start">Payment type: Card/Bank</p>
            <p
              className="col-3 m-0 p-0 text-bold text-end"
              style={{
                color: orders[0].paid ? "green" : "red",
              }}
            >
              {orders[0].paid ? "Paid" : "Not Paid"}
            </p>
          </div>
          <p className="m-0 p-0 pb-1">Reference: {orders[0].reference}</p>
        </div>
      ) : (
        <div className="m-0 p-0 row justify-content-between">
          <div className="m-0 p-0 row">
            <p className="col m-0 p-0 text-start">Payment type: Cash</p>
            <p
              className="col-3 m-0 p-0 text-bold text-end"
              style={{
                color: orders[0].paid ? "green" : "red",
              }}
            >
              {orders[0].paid ? "Paid" : "Not Paid"}
            </p>
          </div>
          <p className="m-0 p-0 pb-1">Reference: {orders[0].reference}</p>
        </div>
      )}
      <br />
      <h5 className="m-0 p-0">Orders</h5>
      <hr className="my-2" />
      {orders.map((order) => (
        <div className="m-0 p-0" key={order.id}>
          <ul className="list-group m-0 p-0">
            {order.cart_items.map((item, index) => (
              <li
                className="d-flex justify-content-between list-group-item border-0 m-0 p-0 pb-1"
                key={index}
              >
                <p className="col-6 m-0 p-0">{item.food_name}</p>
                <p className="col-2 m-0 p-0">x {item.quantity}</p>
                <p className="col-4 text-end m-0 p-0">
                  <FontAwesomeIcon icon="naira-sign" />
                  {item.price}
                </p>
              </li>
            ))}
            <li className="d-flex justify-content-between list-group-item border-0 m-0 p-0 text-first">
              <p className="m-0 p-0">Sub-total:</p>
              <p className="m-0 p-0">
                <FontAwesomeIcon icon="naira-sign" /> {order.price}
              </p>
            </li>
          </ul>
          <hr className="my-2" />
        </div>
      ))}
      <br />
      {orders[0].delivery && (
        <div className="d-flex justify-content-between text-first mb-2">
          <p>Delivery Fee:</p>
          <p>
            <FontAwesomeIcon icon="naira-sign" />
            {orders[0].delivery_fee}
          </p>
        </div>
      )}
      <div className="d-flex justify-content-between text-first">
        <p className="h4">Total:</p>
        <p className="h4">
          <FontAwesomeIcon icon="naira-sign" />
          {orders.reduce((total, order) => total + parseFloat(order.price), 0) +
            (orders[0].delivery ? orders[0].delivery_fee : 0)}
        </p>
      </div>
    </>
  );
}

export default OrderDisplay;
