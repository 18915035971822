import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "../styles/home.css";
import "../styles/index.css";


function Contact() {
  return (
    <div className="container-fluid m-0 p-0">
      <h1 className="text-pop text-center mb-2">Contact Us</h1>
      <div className="row m-0 p-4 justify-content-center align-items-center">
        <div className="col-12 col-lg-6 p-2 p-lg-3">
          <div className="row justify-content-around">
            <div className="col-2 col-md-1">
              <FontAwesomeIcon
                className="bg-first p-2 p-md-3 text-white rounded-3"
                icon="phone"
              />
            </div>
            <div className="col-10">
              <h4>Phone</h4>
              <p>
                Phone us anytime to make an order or to make enquiries: <a href='tel:+2348000000000'>+2348000000000</a>
              </p>
            </div>
          </div>
          <div className="row justify-content-around">
            <div className="col-2 col-md-1">
              <FontAwesomeIcon
                className="bg-first p-2 p-md-3 text-white rounded-3"
                icon="envelope"
              />
            </div>
            <div className="col-10">
              <h4>Mail</h4>
              <p>
                Send our Support Team an email for technical issues: <a href='mailto:support@iwanbuy.ng'><FontAwesomeIcon icon="envelope" /></a>
              </p>
            </div>
          </div>
          <div className="row justify-content-around">
            <div className="col-2 col-md-1">
              <FontAwesomeIcon
                className="bg-first p-2 p-md-3 text-white rounded-3"
                icon="location-dot"
              />
            </div>
            <div className="col-10">
              <h4>Walk In</h4>
              <p>
                Walk in to our store to make enquiries from any of our staffs.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="row m-0 text-center justify-content-center my-4">
        <div className="col-12">
          <h2 className="mb-4 text-inter">Our Menu</h2>
        </div>
        <div className="col-8 col-lg-6 col-xl-4">
          <p>
            We have a wide range of menu to choose from. Click the button below
            to view our menu
          </p>
        </div>
        <div className="col-12">
          <a href="/menu" className="btn btn-dark border-0 bg-first text-light">
            View Menu
          </a>
        </div>
      </div>
    </div>
  );
}

export default Contact;
