import { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { api } from "../api";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import HomeGCard from "../components/HomeGCard";
import CardR from "../components/CardR";
import MealCard from "../components/MealCard";
import bowl from "../images/bowl.jpg";
import "../styles/home.css";
import "../styles/index.css";


function Home({ setCurrentOrder }) {
  const [popularMeals, setPopularMeals] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchPopularMeals = async () => {
      try {
        const response = await api.get("meals/popular");
        setPopularMeals(response.data);
      } catch (error) {
        console.error(error);
      }
    };

    fetchPopularMeals();
  }, []);

  return (
    <div className="container-fluid m-0 p-0">
      <div className="row justify-content-center align-items-center m-0 p-0">
        <div className="col-12 col-lg-7 p-2 text-center">
          <h1 className="text-pop display-3 px-5 py-3">
            Food at your door step - Order now and enjoy
          </h1>
          <p className="px-5 py-2 text-inter">
            Tasty and exciting meals specially prepared just for you at food
            spice. Enjoy a wide range of tasty and healthy meals, click the
            button to order now
          </p>
          <div className="justify-content-center d-flex">
            <a
              href='/menu'
              type="button"
              className="mx-4 btn btn-first py-2 px-4 text-inter border-0"
            >
              Order Now
            </a>
            <Link
              to="/about"
              className="mx-4 btn btn-accent py-2 px-4 text-inter border-0"
            >Learn more</Link>
          </div>
        </div>
        <div className="d-none d-lg-block bowl col-lg-4 p-2 text-center">
          <img src={bowl} alt="bowl" className="img-fluid px-5" />
        </div>
      </div>
      <div className="d-flex d-lg-flex row justify-content-center m-0 my-5 align-items-center">
        <div className="col-10 bg-accent border rounded-4 d-flex">
          <HomeGCard number="350" content="Users" />
          <HomeGCard number="20" content="Foods" />
          <HomeGCard number="1375" content="Orders" />
        </div>
      </div>
      <div className="d-none d-lg-flex row m-0 text-center justify-content-center">
        <div className="col-12">
          <h2 className="mb-4 text-inter">Why choose our favourite food?</h2>
        </div>
        <div className="col-8 col-lg-6 col-xl-4">
          <p>
            We provide the best food for you with a wide
            range of food options to choose from.
          </p>
        </div>
      </div>
      <div className="d-none d-lg-flex card-group m-0 p-5">
        <CardR
          icon="star"
          title="Quality Food"
          text="We provide the best quality food for you and your family. Foods are prepared with your health as first priority"
        />
        <CardR
          icon="money-bill"
          title="Affordable"
          text="Our food is very affordable and we provide the best quality. You can purchase with as low as 500."
        />
        <CardR
          icon="cart-shopping"
          title="Fast Response"
          text="Food collection is faster and checking out is easier when you order online."
        />
      </div>
      <div className="row m-0 p-4">
        <div className="col-12 p-2">
          <h3>Most popular meals</h3>
        </div>
        <div className="col-12 justify-content-center">
          <div className="row m-0 justify-content-start">
            {popularMeals.length !== 0 &&
              popularMeals.map((meal) => (
                <MealCard
                  key={meal.id}
                  image={meal.image ? meal.image : bowl}
                  name={meal.name}
                  onClick={() => {
                    setCurrentOrder(meal.foods);
                    navigate("/menu");
                  }}
                  canvas="#offcanvasNull"
                  setWobble={() => {}}
                  available={meal.foods.every(food => food.available) || meal.available}
                />
              ))}
          </div>
        </div>
        <div
          className="offcanvas offcanvas-end"
          tabIndex="-1"
          id="offcanvasNull"
          aria-labelledby="offcanvasNullLabel"
        >
          <p>You can't see me</p>
        </div>
      </div>
      <div className="row m-0 text-center justify-content-center my-5">
        <div className="col-12">
          <h2 className="mb-4 text-inter">Our Menu</h2>
        </div>
        <div className="col-8 col-lg-6 col-xl-4">
          <p>
            We have a wide range of menu to choose from. Click the button below
            to view our menu
          </p>
        </div>
        <div className="col-12">
          <a href="/menu" className="btn btn-dark border-0 bg-first text-light">
            View Menu
          </a>
        </div>
      </div>
      <div className="row m-0 p-4 justify-content-center align-items-center">
        <div className="d-none d-lg-block bowl col-lg-6 p-2 text-center">
          <img src={bowl} alt="bowl" className="img-fluid" />
        </div>
        <div className="col-12 col-lg-6 p-3 p-lg-5">
          <div className="row justify-content-around">
            <div className="col-2 col-md-1">
              <FontAwesomeIcon
                className="bg-first p-2 p-md-3 text-white rounded-3"
                icon="phone"
              />
            </div>
            <div className="col-10">
              <h4>Customer support</h4>
              <p>
                We provide the best customer support for you and your family.
                Our customer support is available 24/7
              </p>
            </div>
          </div>
          <div className="row justify-content-around">
            <div className="col-2 col-md-1">
              <FontAwesomeIcon
                className="bg-first p-2 p-md-3 text-white rounded-3"
                icon="tags"
              />
            </div>
            <div className="col-10">
              <h4>Best price guaranteed</h4>
              <p>
                We provide the best quality food at affordable pricing. You can
                be sure to get the best quality for the price you pay
              </p>
            </div>
          </div>
          <div className="row justify-content-around">
            <div className="col-2 col-md-1">
              <FontAwesomeIcon
                className="bg-first p-2 p-md-3 text-white rounded-3"
                icon="location-dot"
              />
            </div>
            <div className="col-10">
              <h4>Many locations</h4>
              <p>
                We are available to deliver foods in any location within lagos.
                Delivery is fast and reliable.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Home;
