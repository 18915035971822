import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { api } from "../api";
import Category from "../components/Category";
import MealCard from "../components/MealCard";
import Loader from "../components/Loader";
import SearchBar from "../components/SearchBar";
import Order from "../components/Order";
import bowl from "../images/bowl.jpg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import toast from 'react-hot-toast';

import "../styles/index.css";

function Menu({ currentOrder, setCurrentOrder, cart, setCart }) {
  const [allMeals, setAllMeals] = useState([]);
  const [popularMeals, setPopularMeals] = useState([]);
  const [foods, setFoods] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [wobble, setWobble] = useState(0);

  useEffect(() => {
    const fetchMeals = async () => {
      try {
        const response = await api.get("/meals");
        setAllMeals(response.data);
      } catch (error) {
        console.error(error);
      }
    };

    const fetchPopularMeals = async () => {
      try {
        const response = await api.get("meals/popular");
        setPopularMeals(response.data);
      } catch (error) {
        console.error(error);
      }
    };

    const fetchFoods = async () => {
      try {
        const response = await api.get("/foods");
        setFoods(response.data.sort((first, second) => {
          return second.available - first.available
        }));
      } catch (error) {
        console.error(error);
      }
    };

    fetchMeals();
    fetchPopularMeals();
    fetchFoods();
  }, []);

  const updateMealCount = async (mealId) => {
    try {
      await api.post(`/meals/${mealId}/count`, {});
    } catch (error) {
      console.error(error);
    }
  };

  const handleCardClick = (cardType, item) => {
    toast(
      <div>{item.name} Added to Order</div>,
      {
        icon: <FontAwesomeIcon icon="basket-shopping" className='text-first' />,
      }
    );
    if (cardType === "meal") {
      setCurrentOrder(item.foods);
      updateMealCount(item.id);
    } else {
      const isAlreadyInOrder = currentOrder.some(
        (orderItem) => orderItem.id === item.id
      );
      if (!isAlreadyInOrder) setCurrentOrder([...currentOrder, item]);
    }
  };


  return (
    <div className="container-fluid row m-0 p-3 p-md-4 pt-1">
      <div className="m-0 p-0">
        <div className="row text-center justify-content-center">
          <SearchBar setSearchText={setSearchText} placeholder={"Bread & Beans, rice, Meat . . ."} />
        </div>
        <div className="row justify-content-start">
          <div className="col-12 p-2 pt-0">
            <h3>Categories</h3>
          </div>
          <div className="d-flex flex-row flex-no-wrap overflow-auto">
            <Category category="All items" number={71} />
            <Category category="Rice" number={30} />
            <Category category="Swallow" number={20} />
            <Category category="Soup" number={10} />
            <Category category="Drinks" number={5} />
            <Category category="All items" number={71} />
            <Category category="Rice" number={30} />
            <Category category="Swallow" number={20} />
            <Category category="All items" number={71} />
            <Category category="Rice" number={30} />
            <Category category="Swallow" number={20} />
            <Category category="Soup" number={10} />
            <Category category="Drinks" number={5} />
            <Category category="All items" number={71} />
            <Category category="Rice" number={30} />
            <Category category="Swallow" number={20} />
          </div>
        </div>
        {searchText.length > 0 ? (
          <>
            <div className="row justify-content-center">
              <div className="col-12 col-lg-12 p-2 pb-0 pt-4">
                <h3 className="text-center">Search Results</h3>
              </div>
            </div>
            <div>
              {allMeals.length === 0 ? (
                <Loader />
              ) : (
                (() => {
                  const filteredMeals = allMeals.filter((meal) =>
                    meal.name.toLowerCase().includes(searchText.toLowerCase())
                  );

                  const filteredFoods = foods.filter((food) =>
                    food.name.toLowerCase().includes(searchText.toLowerCase())
                  );

                  return filteredMeals.length === 0 &&
                    filteredFoods.length === 0 ? (
                    <p className="text-center">
                      No results found for {searchText}
                    </p>
                  ) : (
                    <>
                      {currentOrder.length === 0 && (
                        <div className="row justify-content-start">
                          <div className="col-12 col-lg-12 p-2">
                            <h3>Meals</h3>
                          </div>
                          {filteredMeals.map((meal) => (
                            <MealCard
                              key={meal.id}
                              image={meal.image ? meal.image : "/images/foods/default-food.png"}
                              name={meal.name}
                              onClick={() => handleCardClick("meal", meal)}
                              inCurrentOrder={false}
                              setWobble={setWobble}
                              available={meal.foods.every(food => food.available) && meal.available}
                            />
                          ))}
                        </div>
                      )}
                      <div className="row justify-content-start">
                        <div className="col-12 col-lg-12 p-2">
                          <h3>Foods</h3>
                        </div>
                        {filteredFoods.map((food) => (
                          <MealCard
                            key={food.id}
                            image={food.image ? food.image : bowl}
                            name={food.name}
                            onClick={() => handleCardClick("food", food)}
                            orderText="Add"
                            inCurrentOrder={currentOrder.some(
                              (aFood) => aFood.id === food.id
                            )}
                            setWobble={setWobble}
                            available={food.available}
                          />
                        ))}
                      </div>
                    </>
                  );
                })()
              )}
            </div>
          </>
        ) : (
          <>
            {currentOrder.length === 0 && (
              <>
                <div className="row justify-content-start">
                  <div className="col-12 col-lg-12 p-2 pb-0 pt-4">
                    <h3>Most popular meals</h3>
                  </div>
                  <div className='d-flex flex-row flex-no-wrap overflow-auto'>
                    {popularMeals.length === 0 ? (
                      <Loader />
                    ) : (
                      popularMeals.map((meal) => (
                        <MealCard
                          key={meal.id}
                          image={meal.image ? meal.image : bowl}
                          name={meal.name}
                          onClick={() => handleCardClick("meal", meal)}
                          setWobble={setWobble}
                          available={meal.foods.every(food => food.available) && meal.available}
                        />
                      ))
                    )}
                  </div>
                </div>
                {/* <div className="row justify-content-start">
                  <div className="col-12 col-lg-12 p-2">
                    <h3>Swallow</h3>
                  </div>
                  {allMeals.length === 0 ? (
                    <Loader />
                  ) : (
                    allMeals.map((meal) => (
                      <MealCard
                        key={meal.id}
                        image={meal.image ? meal.image : bowl}
                        name={meal.name}
                        onClick={() => handleCardClick("meal", meal)}
                        setWobble={setWobble}
                        available={meal.foods.every(food => food.available) || meal.available}
                      />
                    ))
                  )}
                </div> */}
                {/* <div className="row justify-content-start">
                  <div className="col-12 col-lg-12 p-2">
                    <h3>Rice</h3>
                  </div>
                  {allMeals.length === 0 ? (
                    <Loader />
                  ) : (
                    allMeals.map((meal) => (
                      <MealCard
                        key={meal.id}
                        image={meal.image ? meal.image : bowl}
                        name={meal.name}
                        onClick={() => handleCardClick("meal", meal)}
                        setWobble={setWobble}
                        available={meal.foods.every(food => food.available) || meal.available}
                      />
                    ))
                  )}
                </div> */}
              </>
            )}
            <div className="row justify-content-start">
              <div className="col-12 col-lg-12 p-2">
                <h3>All foods</h3>
              </div>
              <div className='d-flex flex-row flex-no-wrap overflow-auto'>
                {foods.length === 0 ? (
                  <Loader />
                ) : (
                  foods.map((food) => (
                    <MealCard
                      key={food.id}
                      image={food.image ? food.image : "/images/foods/default-food.png"}
                      name={food.name}
                      onClick={() => handleCardClick("food", food)}
                      orderText="Add"
                      price={food.price}
                      inCurrentOrder={currentOrder.some(
                        (aFood) => aFood.id === food.id
                      )}
                      setWobble={setWobble}
                      available={food.available}
                    />
                  ))
                )}
              </div>
            </div>
          </>
        )}
      </div>
      <button
        className="btn btn-dark bg-first floating-btn border-0"
        data-bs-toggle="offcanvas"
        data-bs-target="#offcanvasExample"
        onAnimationEnd={() => setWobble(0)}
        wobble={wobble}
      >
        <FontAwesomeIcon icon="basket-shopping" />
      </button>
      <div
        className="offcanvas offcanvas-end offcanvas-body bg-accent"
        tabIndex="-1"
        id="offcanvasExample"
        aria-labelledby="offcanvasExampleLabel"
      >
        {currentOrder.length !== 0 ? (
          <Order
            currentOrder={currentOrder}
            setCurrentOrder={setCurrentOrder}
            cart={cart}
            setCart={setCart}
          />
        ) : (
          <div>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="offcanvas"
              aria-label="Close"
            ></button>
            <h4 className="text-center pt-5">Your Order is empty.</h4>
            <p className="text-center pb-5">
              Add items to your order by clicking on the 'Add' button on the
              food item, or order now for meals.
            </p>
            <div className="text-center">
              <p>Want to check your outstanding orders?</p>
              <Link to="/cart" className="btn btn-dark bg-first border-0">
                View Cart <FontAwesomeIcon icon="cart-shopping" /> <sup className="">{cart.length}</sup>
              </Link>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default Menu;
