// /* global bootstrap */
import { useContext, useRef, useState, useEffect } from "react";
import { Navigate } from "react-router-dom";
import { useReactToPrint } from "react-to-print";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { api } from "../api";
import { AuthContext } from "../contexts/AuthContext";
import Receipt from "../components/Receipt";
import ProfileEditForm from "../components/ProfileEditForm";
import "../styles/index.css";
import OrderDisplay from "../components/OrderDisplay";

const Sack = () => (
  <svg
    style={{ width: "100px", height: "100px" }}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 512 512"
  >
    <path d="M320 96H192L144.6 24.9C137.5 14.2 145.1 0 157.9 0H354.1c12.8 0 20.4 14.2 13.3 24.9L320 96zM192 128H320c3.8 2.5 8.1 5.3 13 8.4C389.7 172.7 512 250.9 512 416c0 53-43 96-96 96H96c-53 0-96-43-96-96C0 250.9 122.3 172.7 179 136.4l0 0 0 0c4.8-3.1 9.2-5.9 13-8.4zm84 88c0-11-9-20-20-20s-20 9-20 20v14c-7.6 1.7-15.2 4.4-22.2 8.5c-13.9 8.3-25.9 22.8-25.8 43.9c.1 20.3 12 33.1 24.7 40.7c11 6.6 24.7 10.8 35.6 14l1.7 .5c12.6 3.8 21.8 6.8 28 10.7c5.1 3.2 5.8 5.4 5.9 8.2c.1 5-1.8 8-5.9 10.5c-5 3.1-12.9 5-21.4 4.7c-11.1-.4-21.5-3.9-35.1-8.5c-2.3-.8-4.7-1.6-7.2-2.4c-10.5-3.5-21.8 2.2-25.3 12.6s2.2 21.8 12.6 25.3c1.9 .6 4 1.3 6.1 2.1l0 0 0 0c8.3 2.9 17.9 6.2 28.2 8.4V424c0 11 9 20 20 20s20-9 20-20V410.2c8-1.7 16-4.5 23.2-9c14.3-8.9 25.1-24.1 24.8-45c-.3-20.3-11.7-33.4-24.6-41.6c-11.5-7.2-25.9-11.6-37.1-15l0 0-.7-.2c-12.8-3.9-21.9-6.7-28.3-10.5c-5.2-3.1-5.3-4.9-5.3-6.7c0-3.7 1.4-6.5 6.2-9.3c5.4-3.2 13.6-5.1 21.5-5c9.6 .1 20.2 2.2 31.2 5.2c10.7 2.8 21.6-3.5 24.5-14.2s-3.5-21.6-14.2-24.5c-6.5-1.7-13.7-3.4-21.1-4.7V216z" />
  </svg>
);

const Cash = () => (
  <svg
    style={{ width: "100px", height: "100px" }}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 576 512"
  >
    <path d="M64 64C28.7 64 0 92.7 0 128V384c0 35.3 28.7 64 64 64H512c35.3 0 64-28.7 64-64V128c0-35.3-28.7-64-64-64H64zm64 320H64V320c35.3 0 64 28.7 64 64zM64 192V128h64c0 35.3-28.7 64-64 64zM448 384c0-35.3 28.7-64 64-64v64H448zm64-192c-35.3 0-64-28.7-64-64h64v64zM288 160a96 96 0 1 1 0 192 96 96 0 1 1 0-192z" />
  </svg>
);

// Proxy order is used to ensure modal has content on render, content is dynamically changed on button click
const proxyOrder = [
  {
    cart_items: [
      {
        created_at: "2024-07-13T02:08:24",
        food_id: "d938ca57-deea-4fab-a9a4-a5a1102cd550",
        food_name: "Stew",
        id: "5b6b2561-e805-4539-9a5f-dc7c43cb3e8f",
        order_id: "12a43cf3-f161-478b-82d4-b9a5932a908a",
        price: 0,
        quantity: 1,
      },
      {
        created_at: "2024-07-13T02:08:24",
        food_id: "91c3e966-26db-40ee-a5d1-0491bda44ede",
        food_name: "White Rice",
        id: "61ea073f-7445-480d-a9ec-825b6820e5d3",
        order_id: "12a43cf3-f161-478b-82d4-b9a5932a908a",
        price: 800,
        quantity: 4,
      },
      {
        created_at: "2024-07-13T02:08:24",
        food_id: "12351308-d447-4c7a-a00f-8ae023413668",
        food_name: "Pepsi",
        id: "c787257f-b646-4a12-8e29-6e58ca4a11a0",
        order_id: "12a43cf3-f161-478b-82d4-b9a5932a908a",
        price: 300,
        quantity: 1,
      },
    ],
    completed: false,
    created_at: "2024-07-13T02:08:24",
    id: "12a43cf3-f161-478b-82d4-b9a5932a908a",
    paid: false,
    price: 1100,
    receiver_name: "Mike Layo",
    receiver_phone: "+2348103456781",
    reference: null,
    user_id: "2906b5e5-6b2e-43b5-a592-2804f8c47a7c",
  },
];

function Profile() {
  const { user } = useContext(AuthContext);
  const [printOrders, setPrintOrder] = useState(null);
  const [groupedOrders, setGroupedOrders] = useState([]);
  const [showingOrders, setShowingOrders] = useState(false);

  const [singleOrder, setSingleOrder] = useState(proxyOrder);
  const [editingProfile, setEditingProfile] = useState(false);

  const receiptRef = useRef();

  useEffect(() => {
    const groupOrdersByReference = (orders) => {
      const grouped = orders.reduce((acc, order) => {
        (acc[order.reference] = acc[order.reference] || []).push(order);
        return acc;
      }, {});
      return Object.values(grouped);
    };

    const getOrders = () => {
      api
        .get(`users/${user.id}/orders`, { withCredentials: true })
        .then((res) => {
          setGroupedOrders(groupOrdersByReference(res.data));
        })
        .catch((err) => {
          console.log("Error:", err);
        });
    };

    if (user) getOrders();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handlePrint = useReactToPrint({
    content: () => receiptRef.current,
    onAfterPrint: () => setPrintOrder(null),
  });

  useEffect(() => {
    if (printOrders) {
      handlePrint();
    }
  }, [printOrders, handlePrint]);

  return user ? (
    <div className="container-fluid m-0 p-2">
      <h2 className="text-center m-0 p-0 pb-2">Welcome, {user.first_name}</h2>
      <div className="row m-0 p-0 text-center justify-content-center">
        {editingProfile ? (
          <ProfileEditForm setEditingProfile={setEditingProfile} />
        ) : (
          <div className="col-md-8">
            <div className="card mb-3 border-0 bg-light">
              <div className="row g-0">
                <div className="col-md-6 d-flex justify-content-center text-center align-items-center m-0 p-0">
                  <div className="row m-0 p-0 text-center">
                    <div className="col m-2">
                      <div
                        style={{ width: "200px", height: "200px" }}
                        className="d-flex align-items-center justify-content-center border rounded-circle border-0 shadow p-2"
                      >
                        {groupedOrders.reduce(
                          (acc, orders) =>
                            acc +
                            orders.reduce(
                              (acc, order) => acc + parseFloat(order.price),
                              0
                            ),
                          0
                        ) > 100000 ? (
                          <Cash />
                        ) : (
                          <Sack />
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="card-body bg-white shadow rounded-5">
                    <div className="row">
                      <h5 className="card-title">Personal Details</h5>
                      <ul className="list-group list-group-flush">
                        <li className="list-group-item">
                          {user.first_name + " " + user.last_name}
                        </li>
                        <li className="list-group-item">{user.email}</li>
                        <li className="list-group-item">{user.phone_number}</li>
                        <li className="list-group-item">
                          <button
                            className="btn btn-first"
                            type="button"
                            onClick={() => setEditingProfile(true)}
                          >
                            Edit profile
                          </button>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
      <div className="row m-0 mb-3 p-0">
        <div className="m-0 mb-2 p-2 text-center">
          <button
            type="button"
            onClick={() => setShowingOrders(!showingOrders)}
            className="btn btn fs-6 btn-first rounded-3"
          >
            {showingOrders ? "Hide Order History" : "Show Order History"}
          </button>
        </div>
        {/* MAKE THE ORDERS AND TOTAL AMOUNT COUNT UP WITH REACT ANIMATION */}
        {showingOrders ? (
          <div className="row row-cols-2 row-cols-lg-6 g-4 m-0 p-0 justify-content-center">
            <div className="col">
              <div className="card h-100 text-dark m-0 p-0">
                <div className="card-body m-0 p-1">
                  <div className="row justify-content-between m-0 p-0">
                    <div className="col">
                      <p className="m-0 p-0 text-smaller">ORDERS</p>
                      <p className="m-0 p-0">{groupedOrders.length}</p>
                    </div>
                    <div className="col text-end text-white">
                      <FontAwesomeIcon
                        className="rounded-circle p-2 bg-success"
                        icon="basket-shopping"
                      />
                    </div>
                  </div>
                  <div className="card-footer m-0 p-0 text-center">
                    <p className="m-0 p-0 text-smaller">
                      Number of orders placed
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="card h-100 text-dark m-0 p-0">
                <div className="card-body m-0 p-1">
                  <div className="row justify-content-between m-0 p-0">
                    <div className="col">
                      <p className="m-0 p-0 text-smaller">Purchases</p>
                      <p className="m-0 p-0 text-small">
                        <FontAwesomeIcon
                          className="text-small"
                          icon="naira-sign"
                        />
                        {groupedOrders
                          .reduce(
                            (acc, orders) =>
                              acc +
                              orders.reduce(
                                (acc, order) => acc + parseFloat(order.price),
                                0
                              ),
                            0
                          )
                          .toLocaleString("en-US")}
                      </p>
                    </div>
                    <div className="col text-end text-white">
                      <FontAwesomeIcon
                        className="rounded-circle p-2 bg-success"
                        icon="money-bill"
                      />
                    </div>
                  </div>
                  <div className="card-footer m-0 p-0 text-center">
                    <p className="m-0 p-0 text-smaller">
                      Amount spent on orders
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <></>
        )}
        <div className="row row-cols-1 row-cols-md-3 g-4 m-0 p-0">
          {showingOrders &&
            (groupedOrders.length > 0 ? (
              groupedOrders.map((orders, index) => (
                <div key={index} className="col">
                  <div className="card h-100 text-dark mb-2">
                    <div className="card-body row text-small m-0 p-2">
                      <div className="col-10 m-0 p-0">
                        <p className="m-0 p-0 fw-bold">
                          Date:{" "}
                          {new Date(orders[0].created_at).toLocaleDateString()}
                        </p>
                        <p className="m-0 p-0">
                          Receiver: {orders[0].receiver_name}
                        </p>
                      </div>
                      <div className="col-2 m-0 p-0 text-end">
                        <button
                          onClick={() => {
                            setPrintOrder(orders);
                          }}
                          className="btn btn-first"
                        >
                          <FontAwesomeIcon icon="receipt" />
                        </button>
                      </div>
                      <div className="m-0 p-0">
                        {orders[0].reference &&
                        orders[0].reference.startsWith("O") ? (
                          <div className="m-0 p-0 row justify-content-between">
                            <div className="m-0 p-0 row">
                              <p className="col m-0 p-0 text-start">
                                Payment: Online
                              </p>
                              <p className="col-3 m-0 p-0 text-success text-bold text-end">
                                Paid
                              </p>
                            </div>
                            <p className="m-0 p-0">
                              Ref: {orders[0].reference}
                            </p>
                          </div>
                        ) : (
                          <div className="m-0 p-0 row justify-content-between">
                            <div className="m-0 p-0 row">
                              <p className="col m-0 p-0 text-start">
                                Payment: Cash
                              </p>
                              <p
                                className="col-3 m-0 p-0 text-bold text-end"
                                style={{
                                  color: orders[0].paid ? "green" : "red",
                                }}
                              >
                                {orders[0].paid ? "Paid" : "Not Paid"}
                              </p>
                            </div>
                            <p className="m-0 p-0">
                              Ref: {orders[0].reference}
                            </p>
                          </div>
                        )}
                      </div>
                      <div className="m-0 p-0 text-end">
                        <button
                          type="button"
                          className="btn btn-first p-1 text-small"
                          onClick={() => {
                            setSingleOrder(orders);
                          }}
                          data-bs-toggle="modal"
                          data-bs-target="#viewMoreModal"
                        >
                          View More
                        </button>
                      </div>
                    </div>
                    <div className="card-footer m-0 p-0">
                      <div className="m-0 p-0 p-2 d-flex justify-content-between text-first">
                        <p className="h6 m-0">Total:</p>
                        <p className="h6 m-0">
                          <FontAwesomeIcon icon="naira-sign" />
                          {orders.reduce(
                            (acc, order) => acc + parseFloat(order.price),
                            0
                          ) + (orders[0].delivery ? orders[0].delivery_fee : 0)}
                        </p>
                      </div>
                      <div style={{ display: "none" }}>
                        {printOrders && (
                          <Receipt ref={receiptRef} orders={printOrders} />
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              ))
            ) : (
              <p className="text-center">
                No previous orders. Navigate to the menu page to order your
                favorite meal now
              </p>
            ))}
        </div>
      </div>
      {singleOrder && (
        <div
          className="modal fade"
          id="viewMoreModal"
          tabIndex="-1"
          aria-labelledby="viewMoreModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-dialog-scrollable">
            <div className="modal-content">
              <div className="modal-header">
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>
              <div className="modal-body">
                <div className="m-0 p-0 row">
                  <div className="col">
                    <h5 className="text-dark m-0 p-0 pb-2">
                      Order Details{" "}
                      {singleOrder[0].reference
                        ? ": " + singleOrder[0].reference
                        : ""}
                    </h5>
                  </div>
                  <div className="col-2 text-end">
                    {singleOrder[0].completed ? (
                      <FontAwesomeIcon
                        icon="check-circle"
                        className="fs-3 text-success"
                      />
                    ) : (
                      <FontAwesomeIcon
                        icon="times-circle"
                        className="fs-3 text-danger"
                      />
                    )}
                  </div>
                </div>
                <hr className="my-2" />
                <OrderDisplay orders={singleOrder} />
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={() => setPrintOrder(singleOrder)}
                >
                  Print Receipt
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  ) : (
    <Navigate replace to="/login" />
  );
}

export default Profile;
