import google from "../images/google.png";
import facebook from "../images/facebook.png";
import apple from "../images/apple.png";

import "../styles/index.css";

function Footer() {
  return (
    <footer className="container-fluid px-4 py-1 bg-dark text-white">
      <div className="row">
        <div className="col-12 col-lg-6 p-2">
          <div className="row">
            <h2>IWanBuy</h2>
            <p>Your one place for tasty and quality meals</p>
          </div>
          <div className="row text-center align-items-center justify-content-start">
            <div className="col-2 col-lg-1">
              <img width="40" src={google} alt="google" />
            </div>
            <div className="col-2 col-lg-1">
              <img width="40" src={apple} alt="apple" />
            </div>
            <div className="col-2 col-lg-1">
              <img width="40" src={facebook} alt="facebook" />
            </div>
          </div>
        </div>
        <div className="col-12 col-lg-3 p-2">
          <h3>Info</h3>
          <div className='list-group list-group-flush'>
            <a href='/' className='list-group-item bg-dark text-white'>Home</a>
            <a href='/menu' className='list-group-item bg-dark text-white'>Menu</a>
            <a href='/about' className='list-group-item bg-dark text-white'>About</a>
            <a href='/contact' className='list-group-item bg-dark text-white'>Contact</a>
          </div>
        </div>
        <div className="col-12 col-lg-3 p-2">
          <h3>Socials</h3>
          <div className='list-group list-group-flush'>
            <a href='/' className='list-group-item bg-dark text-white'>FaceBook</a>
            <a href='/' className='list-group-item bg-dark text-white'>Twitter</a>
            <a href='/' className='list-group-item bg-dark text-white'>Instagram</a>
            <a href='/' className='list-group-item bg-dark text-white'>Whatsapp</a>
          </div>
        </div>
      </div>
      <div className="row justify-content-between">
        <div className="col-12 col-lg-6 p-2">
          <p>© 2021 Food Spice. All rights reserved</p>
        </div>
        <div className="col-12 col-lg-6 p-2 d-block d-lg-flex">
          <div className="col-12 col-lg-6">
            <a className='text-white link-underline link-underline-opacity-0' href='/'>Privacy Policy</a>
          </div>
          <div className="col-12 col-lg-6">
            <a className='text-white link-underline link-underline-opacity-0' href='/'>Terms and Conditions</a>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
