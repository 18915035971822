import "../styles/index.css";


function MealCard({ image, name, onClick, price, orderText, inCurrentOrder, setWobble, available }) {
  return (
    <div className="col-6 col-md-4 col-lg-3 col-xl-2 rounded-top-5 m-0 p-0">
      <div className="card bg-light border border-0 rounded-top-5 m-1 shadow">
        <img src={image} alt="meal" className="card-img-top card-img-sz rounded-top-5" />
        <div className="card-body">
          <p className="card-title">{name}</p>
          <div className="d-flex justify-content-between">
            {inCurrentOrder ? (
              <p className='m-0 p-0 text-success'>Added</p>
            ) : (
              <>
                {available ? (
                  <button
                    onClick={() => {
                      onClick();
                      setWobble(1);}
                    }
                    className="btn btn-first text-inter rounded-4 border-0" >
                    {orderText || "Order now"}
                  </button>
                ) : (
                  <button
                    className="btn disabled btn-first text-inter rounded-4 border-0" >
                    Unavailable
                  </button>
                )}
              </>
            )}
            {
              price ?
              <>
                <p className="card-text">₦{price}</p>
              </>
              :
              <></>
            }
          </div>
        </div>
      </div>
    </div>
  );
}

export default MealCard;
